export const brandChile = [                              
  { label: "Caterpillar", value: "cat", idVtex:'catcl'},
  { label: "Columbia", value: "clb", idVtex:'columbiacl'},
  { label: "Merrell", value: "mll", idVtex:'merrellcl'},
  { label: "Under Armour", value: "uar" , idVtex:'underarmourcl'},
  { label: "Jansport", value: "jan", idVtex:'jansportcl'},
  { label: "Norseg", value: "nsg", idVtex:'norsegcl'},
  { label: "Rockford", value: "rkf", idVtex:'rockfordcl'},
  { label: "Azaleia", value: "aza" , idVtex:'azaleiacl'},
  { label: "Bsoul", value: "bas", idVtex:'bodyandsoulcl'},
  { label: "Zapatos", value: "zpt", idVtex:'zapatoscl'},
  { label: "7veinte", value: "7ve", idVtex:'7veintecl'},
  { label: "Hush Puppies", value: "hpp" , idVtex:'hushpuppiescl'},
  { label: "Hush Puppies Kids", value: "hpk" , idVtex:'hushpuppieskidscl'},
  { label: "Keds", value: "kds", idVtex:'kedscl'},
  { label: "Mountain Hardwear", value: "mhw", idVtex:'mountainhardwearcl'},
  { label: "Burton", value: "brt", idVtex:'burtoncl'},
  { label: "Billabong", value: "bll", idVtex:'billabongcl'},
  { label: "We Love Shoes", value: "wls", idVtex:'wlscl'},
  { label: "Vans", value: "vans", idVtex:'vanscl'},
  { label: "Calpany", value: "cal", idVtex:'calpanycl'},
  { label: "Brooks", value: "bks", idVtex:'brookscl'},
  { label: "Element", value: "ele", idVtex:'elementcl'},
  { label: "Rvca", value: "rva", idVtex:'rvcacl'},
  { label: "Sorel", value: "srl", idVtex:'sorelcl'},
  { label: "Crocs", value: "crs", idVtex:'crocscl'},
]

export const brandPeru = [
  { label: "Hush Puppies", value: "hpp" , idVtex:'hushpuppiespe'},
  { label: "Keds", value: "kds", idVtex:'kedspe'},
  { label: "Rockford", value: "rkf", idVtex:'rockfordpe'},
  { label: "Columbia", value: "clb", idVtex:'columbiape'},
  { label: "Billabong", value: "bll", idVtex:'billabongpe'},
  { label: "Jansport", value: "jan", idVtex:'jansportcl'},
  { label: "Patagonia", value: "pat", idVtex:'patagoniape'},
  { label: "Bsoul", value: "bso", idVtex:'bsoulpe'},
  { label: "Supermall", value: "sml", idVtex:'supermallpe'},
  { label: "Shopstar", value: "shp", idVtex:'shopstarpe'},
]

export const brandColombia = [
  { label: "Caterpillar", value: "cat", idVtex:'catco'},
  { label: "Columbia", value: "clb", idVtex:'columbiaco'},
  { label: "Hush Puppies", value: "hpp" , idVtex:'hushpuppiesco'},
  { label: "Merrell", value: "mll", idVtex:'merrellco'},
  { label: "Under Armour", value: "uar" , idVtex:'underarmourco'},
  { label: "Rockford", value: "rkf", idVtex:'rockfordco'},
  { label: "Bsoul", value: "bas", idVtex:'bodyandsoulco'},
  { label: "Norseg", value: "nsg", idVtex:'norsegcol'},
]

export const brandUruguay = []